/**
 * @module Icons
 * Icons for Features
 */

// const icons = require("../images/icons/features");
// const lottie = require('lottie-web');

export class Icons {

    constructor() {


        // let animations = {};
        //
        // for (let i = 0; i < this.options.iconsArray.length; ++i) {
        //     let iconName = this.options.iconsArray[i]
        //
        //     let anim = lottie.loadAnimation({
        //         container: document.getElementById(iconName),
        //         autoplay: false,
        //         loop: false,
        //         animationData: icons[iconName],
        //         renderer: 'svg'
        //     });
        //
        //     animations[iconName] = anim;
        // }

        $('.features__list li').hover(
            function() {
                let icon = $(this).find('img');
                let iconHovered = icon.data('hover');
                icon.attr('src', iconHovered);
            },
            function() {
                let icon = $(this).find('img');
                let iconStatic = icon.data('static');
                icon.attr('src', iconStatic);
            }
            // animations[iconName].goToAndPlay(1, true);
        );

    }
}

